import React, { useEffect, useContext, useState } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Header from './layout/Header'
import Landing from './layout/Landing'
import Footer from './layout/Footer'
import ContentPage from './components/ContentPage'
import ContentPageApi from './components/ContentPageApi'
import Contact from './components/Contact'
import News1 from './components/News1'
import Projects1 from './components/Projects1'
import Search from './components/Search'
import { LanguageContext } from './components/contexts/LanguageContext';
import FooterMenu1 from './components/FooterMenu1'
import NMSW from './components/NMSW'
function AnimatedRoutes() {
  const location = useLocation()
  const { pathname } = useLocation();
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, [pathname]);


  return (
    <>
      <Header />
      <AnimatePresence
        mode='popLayout'>
        <Routes location={location} key={location.pathname}>
          {/* Redirect '/' to a default language */}
          <Route path='/' element={<Navigate to={`${language}`} />} />

          {/* Route with the language parameter */}
          <Route path='/:lang' element={<Landing />} />

          {/* Other routes with the language parameter */}
          <Route path='/:lang/content/9' element={<Contact />} />
          <Route path='/:lang/content/:id' element={<ContentPageApi />} />

          <Route path='/:lang/news/:id' element={<News1 />} />
          <Route path='/:lang/projects/:id' element={<Projects1 />} />

          <Route path='/:lang/search/:id' element={<Search />} />

          <Route path='/:lang/fcontent/:id' element={<FooterMenu1 />} />

          <Route path='/:lang/NMSW' element={<NMSW />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default AnimatedRoutes